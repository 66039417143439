<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';
import DialogProcedimentos from './dialogs/DialogProcedimentos.vue';
import { mCPF } from '@/utils/Mask';

const emit = defineEmits(['metadados', 'update:loading', 'update:depositosSelecionados']);
const props = defineProps({
    value: Array,
    metadados: Object,
    loading: Boolean,
    depositosSelecionados: Array
});

const prestadorId = computed(() => props.depositosSelecionados?.[0]?.prestadorId);
const loadingTabela = computed({
    get() {
        return props.loading;
    },
    set(novoValor) {
        emit('update:loading', novoValor);
    }
});

const todosSelecionados = ref(false);
function selecionarTodos(valor) {
    const depositosMesmoPrestador = props.value.filter((deposito) => {
        return deposito.prestadorId === prestadorId.value;
    });

    todosSelecionados.value = valor;
    emit('update:depositosSelecionados', valor ? depositosMesmoPrestador : []);
}

const dialogProcedimentos = ref(false);
function abrirDialogProcedimentos(item) {
    const { idAgendamentoDeposito, idAgendamento } = item;
    dialogProcedimentos.value?.abrir(idAgendamentoDeposito, idAgendamento);
}

function ajustarFusoHorarioAgendamento(hora) {
    if (!hora) return '';
    const [hours, minutes] = hora.split(':').map(Number);
    const date = new Date();
    date.setHours(hours - 3, minutes);

    return date.toTimeString().slice(0, 5);
}

function formatarDataAgendamento(data) {
    if (!data) return '';
    const date = new Date(data);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
}
</script>
<template>
    <div class="flex flex-column pt-4">
        <div v-if="depositosSelecionados?.length" class="field-checkbox pb-3 m-0">
            <Checkbox id="selecionar-todos" :model-value="todosSelecionados" binary @update:model-value="selecionarTodos" />
            <label for="selecionar-todos"> Selecionar todos do mesmo prestador </label>
        </div>
        <DataTable
            :loading="loadingTabela"
            :value="value"
            :rows="metadados?.itensPorPagina"
            :totalRecords="metadados?.total"
            class="p-0"
            @page="$emit('metadados', $event)"
            @sort="$emit('metadados', $event)"
        >
            <Column field="selecionar" style="width: 30px">
                <template #body="{ data }">
                    <div class="flex items-center w-4">
                        <Checkbox
                            :model-value="depositosSelecionados"
                            :binary="false"
                            :disabled="!!prestadorId && data?.prestadorId !== prestadorId"
                            :value="data"
                            label=""
                            @update:model-value="$emit('update:depositosSelecionados', $event)"
                        />
                    </div>
                </template>
            </Column>
            <Column field="funcionario" header="Nome funcionário">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div class="font-medium">
                            {{ data.nomeFuncionario }}
                        </div>
                        <div class="text-500 flex gap-2">
                            <span>
                                {{ data.idFuncionario }}
                            </span>

                            <span>
                                {{ mCPF(data.cpfFuncionario) }}
                            </span>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="horaAgendamento" header="Hora" style="width: 6%">
                <template #body="{ data }">
                    {{ ajustarFusoHorarioAgendamento(data.horaAgendamento) }}
                </template>
            </Column>
            <Column field="dataAgendamento" header="Data" style="width: 8%">
                <template #body="{ data }">
                    {{ formatarDataAgendamento(data.dataAgendamento) }}
                </template>
            </Column>
            <Column field="prestador" header="Prestador" style="width: 15%"></Column>
            <Column field="empresa" header="Empresa" style="width: 20%">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div class="font-medium">
                            {{ data.rasaoSocialEmpresa }}
                        </div>
                        <div class="text-500">
                            {{ data.nomeFantasiaEmpresa }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="procedimentos" header="Procedimentos" body-class="px-0" style="width: 12%">
                <template #body="{ data }">
                    <Button class="p-button-text" icon="pi pi-calendar" label="Ver procedimentos" @click="abrirDialogProcedimentos(data)" />
                </template>
            </Column>
            <Column field="valor" header="Valor" style="width: 8%">
                <template #body="{ data }">
                    {{ (+data.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                </template>
            </Column>
        </DataTable>
        <Paginator
            :rows="metadados?.itensPorPagina"
            :totalRecords="metadados?.total"
            @page="$emit('metadados', $event)"
            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
        ></Paginator>
    </div>
    <DialogProcedimentos ref="dialogProcedimentos" />
</template>
