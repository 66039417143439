<script setup>
import DialogPagamento from './dialogs/DialogPagamento.vue';
import DialogGerarPdf from './dialogs/DialogGerarPdf.vue';
import DialogFiltros from './dialogs/DialogFiltros.vue';
import BaseService from '@/services/BaseService';
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['atualizar', 'update:loading', 'update:metadadosTabela', 'update:depositosSelecionados']);
const props = defineProps({
    abaAtiva: String,
    depositosSelecionados: Array,
    loading: Boolean,
    metadadosTabela: Object
});

const abaPendentes = computed(() => {
    return props.abaAtiva === 'pendentes';
});
const depositosIds = computed(() => {
    return props.depositosSelecionados.map((deposito) => {
        return deposito.idAgendamentoDeposito;
    });
});
const filtrosPreenchidos = computed(() => {
    const valores = Object.values(metadados.value?.filtrosExtras || {});

    return valores?.some(Boolean);
});
const prestadorId = computed(() => {
    return props.depositosSelecionados?.[0]?.prestadorId;
});
const loadingAcoes = computed({
    get() {
        return props.loading;
    },
    set(novoValor) {
        emit('update:loading', novoValor);
    }
});
const metadados = computed({
    get() {
        return props.metadadosTabela;
    },
    set(novoValor) {
        emit('update:metadadosTabela', novoValor);
    }
});

const toast = useToast();
async function atualizarValores() {
    loadingAcoes.value = true;
    try {
        const service = new BaseService('agendamento-deposito/buscar-procedimentos-valores');
        for (const agendamentoDepositoId of depositosIds.value) {
            await service.save({
                agendamentoDepositoId,
                prestadorId: prestadorId.value
            });
        }

        atualizar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao atualizar os valores! ',
            life: 3000
        });
    }
    loadingAcoes.value = false;
}

function atualizar() {
    emit('atualizar');
}

function limparFiltrosExtras() {
    metadados.value.filtrosExtras = {};
    atualizar();
}

function limparSelecionados() {
    emit('update:depositosSelecionados', []);
    atualizar();
}

const dialogFiltros = ref(null);
function abrirDialogFiltros() {
    dialogFiltros.value?.abrir();
}

const dialogGerarPdf = ref(null);
function abrirDialogGerarPdf() {
    dialogGerarPdf.value?.abrir();
}

const dialogPagamento = ref(null);
async function abrirDialogPagamento() {
    await atualizarValores();
    const prestadorId = props.depositosSelecionados?.[0]?.prestadorId;
    if (!prestadorId) return;

    dialogPagamento.value?.abrir(prestadorId);
}
</script>
<template>
    <div class="flex justify-content-between">
        <div>
            <InputText v-model="metadados.filter" class="w-25rem mr-5" placeholder="Pesquisar por funcionário ou CPF" @keyup.enter="atualizar" />
            <Button class="p-button h-full w-4rem mr-2" icon="pi pi-filter" @click="abrirDialogFiltros" />
            <Button
                v-if="filtrosPreenchidos"
                class="p-button p-button-outlined h-full w-4rem"
                icon="pi pi-filter-slash"
                @click="limparFiltrosExtras"
            />
        </div>
        <div class="flex gap-3">
            <Button class="p-button p-button-outlined h-full" icon="pi pi-file-export" label="Gerar valores pagos" @click="abrirDialogGerarPdf" />
            <Button
                :disabled="!depositosSelecionados?.length || !abaPendentes"
                :class="{ 'text-gray-400': !depositosSelecionados?.length || !abaPendentes }"
                class="p-button p-button-outlined h-full"
                icon="pi pi-refresh"
                label="Atualizar valores"
                @click="atualizarValores"
            />
            <Button
                :disabled="!depositosSelecionados?.length || !abaPendentes"
                :class="{ 'bg-gray-400 border-gray-400': !depositosSelecionados?.length || !abaPendentes }"
                class="p-button h-full"
                label="Pagar"
                @click="abrirDialogPagamento"
            />
        </div>
        <DialogGerarPdf ref="dialogGerarPdf" />
        <DialogFiltros ref="dialogFiltros" v-model:filtrosExtras="metadados.filtrosExtras" @atualizar="atualizar" />
        <DialogPagamento ref="dialogPagamento" :depositosSelecionados="depositosSelecionados" @atualizar="limparSelecionados" />
    </div>
</template>
