<script setup>
import BaseService from '@/services/BaseService';
import { ref, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const loading = ref(false);
const visivel = ref(false);
const procedimentos = ref([]);
const totalProcedimentos = ref(null);
const agendamentoId = ref(null);

async function getProcedimentosAgendamento(agendamentoDepositoId) {
    loading.value = true;
    try {
        const url = `/agendamento-deposito/listar-procedimentos-deposito/${agendamentoDepositoId}`;
        const service = new BaseService(url);
        const { data } = await service.findAll({});
        procedimentos.value = data.map((procedimento) => ({
            ...procedimento,
            valorProcedimento: formatarParaReal(procedimento.valorProcedimento)
        }));
        const soma = data.reduce((soma, procedimento) => {
            return soma + parseFloat(procedimento.valorProcedimento);
        }, 0);
        totalProcedimentos.value = formatarParaReal(soma);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao buscar procedimentos!',
            life: 3000
        });
    }
    loading.value = false;
}

function formatarParaReal(valor) {
    return (+valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

function abrir(agendamentoDepositoId, idAgendamento) {
    getProcedimentosAgendamento(agendamentoDepositoId);
    agendamentoId.value = idAgendamento;
    visivel.value = true;
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog :visible="visivel" modal style="width: 644px; max-width: 90%" @update:visible="visivel = false">
        <AppLoadingWrapper v-if="loading" />
        <template v-if="!loading" #header>
            <div class="flex justify-content-between align-items-center">
                <div @click="getProcedimentosAgendamento">
                    <h3>Procedimentos de {{ procedimentos[0]?.nomefuncionario }}</h3>
                    <h5 class="text-gray-500 mt-1">Cód.agendamento {{ agendamentoId }}</h5>
                </div>
            </div>
        </template>
        <div v-if="!loading" class="grid max-h-100rem md:max-h-full overflow-y-auto overflow-x-hidden mt-1 px-3">
            <section class="flex flex-column w-full">
                <DataTable :value="procedimentos" class="p-0">
                    <Column field="procedimento" header="Procedimentos" style="width: 25%"></Column>
                    <Column field="data" header="Data" style="width: 5%"></Column>
                    <Column field="hora" header="Hora" style="width: 5%"></Column>
                    <Column field="valorProcedimento" header="Valor" style="width: 5%"></Column>
                </DataTable>
                <div class="flex justify-content-end mt-6">
                    Total <span class="font-bold ml-2">{{ totalProcedimentos }}</span>
                </div>
            </section>
        </div>
    </Dialog>
</template>
