<script setup>
import CabecalhoAcoes from './components/CabecalhoAcoes.vue';
import TabelaPendentes from './components/TabelaPendentes.vue';
import TabelaConcluidos from './components/TabelaConcluidos.vue';
import BaseService from '@/services/BaseService';
import { useToast } from 'primevue/usetoast';
import { onMounted, ref } from 'vue';

const abaAtiva = ref('pendentes');
const loading = ref(false);
const depositosSelecionados = ref([]);

const toast = useToast();
const metadadosTabela = ref({ sort: {} });
const depositosAntecipados = ref([]);
async function getDepositosAntecipados() {
    loading.value = true;
    try {
        depositosAntecipados.value = [];
        const url = `agendamento-deposito/${abaAtiva.value}`;
        const service = new BaseService(url);
        const { data } = await service.findAll(metadadosTabela.value);

        depositosAntecipados.value = data.items;
        metadadosTabela.value.total = data.meta.totalItems;
        metadadosTabela.value.itensPorPagina = data.meta.itemsPerPage;
        metadadosTabela.value.page = data.meta.currentPage;
        metadadosTabela.value.sort = {};
        atualizarSelecionados();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao buscar registros de deposito antecipado! ',
            life: 3000
        });
    }
    loading.value = false;
}

function atualizarMetadadosTabela(metadados) {
    if (metadados.page !== undefined) {
        metadadosTabela.value.page = metadados.page + 1;
    }
    if (metadados.sortField) {
        metadadosTabela.value.sort = {
            [metadados.sortField]: metadados.sortOrder === 1 ? 'ASC' : 'DESC'
        };
    }

    getDepositosAntecipados();
}

function atualizarSelecionados() {
    const selecionadosIds = depositosSelecionados.value.map((deposito) => {
        return deposito.idAgendamentoDeposito;
    });
    depositosSelecionados.value = depositosAntecipados.value.filter((deposito) => {
        return selecionadosIds.includes(deposito.idAgendamentoDeposito);
    });
}

const onTabChange = (e) => {
    abaAtiva.value = e.index ? 'concluidos' : 'pendentes';
    getDepositosAntecipados();
};

onMounted(() => {
    getDepositosAntecipados();
});
</script>
<template>
    <div class="card flex flex-column" style="padding: 32px; gap: 16px">
        <h1 class="font-semibold text-2xl m-0">Depósito Antecipado</h1>
        <CabecalhoAcoes
            v-model:loading="loading"
            v-model:metadadosTabela="metadadosTabela"
            v-model:depositosSelecionados="depositosSelecionados"
            :abaAtiva="abaAtiva"
            @atualizar="getDepositosAntecipados"
        />
        <TabView :pt="{ panelContainer: { class: 'p-0' } }" @tabChange="onTabChange">
            <TabPanel header="Pendente">
                <TabelaPendentes
                    v-model:depositosSelecionados="depositosSelecionados"
                    v-model:loading="loading"
                    :metadados="metadadosTabela"
                    :value="depositosAntecipados"
                    @metadados="atualizarMetadadosTabela"
                />
            </TabPanel>
            <TabPanel header="Concluído">
                <TabelaConcluidos
                    v-model:loading="loading"
                    :metadados="metadadosTabela"
                    :value="depositosAntecipados"
                    @metadados="atualizarMetadadosTabela"
                />
            </TabPanel>
        </TabView>
    </div>
</template>
